import dynamic from 'next/dynamic';
import React from 'react';
import type { HomePageSection } from 'types';

const HeaderSection = dynamic(() => import('./Sections/HeaderSection'));
const MentorChatSection = dynamic(() => import('./Sections/MentorChatSection/MentorChatSection'));
const FeaturesSection = dynamic(() => import('./Sections/FeaturesSection/FeaturesSection'));
const BlogSection = dynamic(() => import('./Sections/BlogSection/BlogSection'));
const FAQSection = dynamic(() => import('./Sections/FAQSection/FAQSection'));
const RatingSection = dynamic(() => import('./Sections/RatingSection/RatingSection'));
const MentorXSection = dynamic(() => import('./Sections/MentorXSection/MentorXSection'));
const CourseFinderSection = dynamic(() => import('./Sections/CourseFinderSection/CourseFinderSection'));
const AdmitKardInMedia = dynamic(() => import('./Sections/AdmitKardInMedia/AdmitKardInMedia'));
const TestimonialSection = dynamic(() => import('./Sections/TestimonialSection/TestimonialSection'));

interface HeaderRendererProps {
  section: HomePageSection;
}

export const SectionRenderer: React.FunctionComponent<HeaderRendererProps> = (props) => {
  switch (props.section) {
    case 'section-header': return (<HeaderSection/>);
    case 'section-testimonial': return (<TestimonialSection/>);
    case 'section-features': return (<FeaturesSection/>);
    case 'section-mentor-chat': return (<MentorChatSection/>);
    case 'section-course-finder': return (<CourseFinderSection/>);
    case 'section-mentor-x': return (<MentorXSection/>);
    case 'section-blog': return (<BlogSection/>);
    case 'section-admitkard-in-media': return (<AdmitKardInMedia/>);
    case 'section-faq': return (<FAQSection/>);
    case 'section-rating': return (<RatingSection/>);
    default: return (<></>);
  }
};
