import { SuaveContainer } from '@admitkard/ccl';
import { SectionRenderer } from 'components/Home/HomeV2/Components/SectionRenderer';
import { HomePageConfigContext } from 'components/Home/HomeV2/contexts';
import React from 'react';
import type { HomePageConfig } from 'types';

interface HomeV2Props {
  config: HomePageConfig
}

const HomeV2: React.FunctionComponent<HomeV2Props> = (props) => {
  return (
    <HomePageConfigContext.Provider value={props.config} >
      <SuaveContainer styles={{
        gap: 'clamp(1.5rem, 10vw, 5.25rem)',
        paddingBottom: 'clamp(1.5rem, 8vw, 4rem)'
      }}>
        <HomePageConfigContext.Consumer>
          {
            (config) =>  config.sectionConfig.sections
              .map((section) => (<SectionRenderer
                key={section}
                section={section}
              />))
          }
        </HomePageConfigContext.Consumer>
      </SuaveContainer>
    </HomePageConfigContext.Provider>
  );
};

export default HomeV2;
